import { Map, Record } from 'immutable';

export interface CampaignState extends Map<string, any> {

    campaignList: any;
    campaignListLoading: boolean;
    campaignListLoaded: boolean;
    campaignListFailed: boolean;

    campaignListCount: any;
    campaignListCountLoading: boolean;
    campaignListCountLoaded: boolean;
    campaignListCountFailed: boolean;

    createCampaign: any;
    createCampaignLoading: boolean;
    createCampaignLoaded: boolean;
    createCampaignFailed: boolean;

    get_hours_to_dial: any;
    get_hours_to_dialLoading: boolean;

    get_minutes_to_dial: any;
    get_minutes_to_dialLoading: boolean;

    stopCamp: any;
    stopCampLoading: boolean;

    generateCash: any;
    generateCashLoading: boolean;

    updateApplicationPaymentStatus: any;
    updateApplicationPaymentStatusLoading: boolean;

    organisationCredits: any;
    organisationCreditsLoading: boolean;

    CreditTransactionList: any;
    CreditTransactionListLoading: boolean;
    CreditTransactionListLoaded: boolean;
    CreditTransactionListFailed: boolean;

    CreditTransactionListCount: any;
    CreditTransactionListCountLoading: boolean;
    CreditTransactionListCountLoaded: boolean;
    CreditTransactionListCountFailed: boolean;

}

export const campaignStateRecord = Record({

    campaignList: [],
    campaignListLoading: false,
    campaignListLoaded: false,
    campaignListFailed: false,

    campaignListCount: 0,
    campaignListCountLoading: false,
    campaignListCountLoaded: false,
    campaignListCountFailed: false,

    createCampaign: {},
    createCampaignLoading: false,
    createCampaignLoaded: false,
    createCampaignFailed: false,

    get_hours_to_dial: [],
    get_hours_to_dialLoading: false,

    get_minutes_to_dial: [],
    get_minutes_to_dialLoading: false,

    stopCamp: [],
    stopCampLoading: false,

    generateCash: [],
    generateCashLoading: false,

    updateApplicationPaymentStatus: {},
    updateApplicationPaymentStatusLoading: false,

    organisationCredits: {},
    organisationCreditsLoading: false,

    CreditTransactionList: [],
    CreditTransactionListLoading: false,
    CreditTransactionListLoaded: false,
    CreditTransactionListFailed: false,

    CreditTransactionListCount: 0,
    CreditTransactionListCountLoading: false,
    CreditTransactionListCountLoaded: false,
    CreditTransactionListCountFailed: false,

});


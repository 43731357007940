import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { ProjectsSandbox } from 'src/app/pages/projects/projects.sandbox';
import { LiveMoniterSandbox } from 'src/app/pages/live-moniter/liveMoniter.sandbox';

@Component({
  selector: 'app-click-to-call-multino-list',
  templateUrl: './click-to-call-multino-list.component.html',
  styleUrls: ['./click-to-call-multino-list.component.scss']
})
export class ClickToCallMultiNoListComponent implements OnInit, OnDestroy {
  customerDetails: any;
  currentMobileNo = true;
  public subscriptions: Array<Subscription> = [];
  constructor(
    public activeModal: NgbActiveModal,
    public projectsSandbox: ProjectsSandbox,
    public liveMoniterSandbox: LiveMoniterSandbox,
  ) { }

  ngOnInit() {
  }
  getNumbersToCall(){
    let params: any = {};
    params.customer_id = this.customerDetails.customer_id;
    params.customer_number = this.currentMobileNo ? this.customerDetails.customer_mobile: this.customerDetails.alternate_number1;
    this.liveMoniterSandbox.clickToCall(params);
    this.subscriptions.push(this.liveMoniterSandbox.clickToCallLoading$.subscribe((res) => {
      if (res) {
        this.activeModal.close();
      }
    }))
    
  }
  changeData(event){
    this.currentMobileNo = !this.currentMobileNo;
    
  }
  close() {
    this.activeModal.close();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(each => each.unsubscribe());
  }
}

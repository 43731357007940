

export const environment = {
  production: true,
  baseUrl: 'http://smartcall.dialoutworks.com/v1',
  // baseUrl: 'http://smartcalltest.dialoutworks.com/v1',
  // baseUrl: 'http://vgntalkdesk.dialoutworks.com/v1',  // Live Url
  cryptoSecretKey: '7uGYcpaWrQcV5R+46vh6O2ZA3wc9msuhFRzSSXs3',
  // awsAccessKey: 'AKIA25TQGEBWZCIGHNVJ',
  // awsSecretKey: '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',
};


// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // baseUrl: 'http://smartcall.dialoutworks.com/v1',
  baseUrl: 'https://smartcall.dialoutworks.com/v1',
  // baseUrl: 'http://localhost:20020',
  // baseUrl: 'http://vgntalkdesk.dialoutworks.com/v1', //live URL
  cryptoSecretKey: '7uGYcpaWrQcV5R+46vh6O2ZA3wc9msuhFRzSSXs3',


  // awsAccessKey: 'AKIA25TQGEBWZCIGHNVJ',
  // awsSecretKey: '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',
  razorpay_Key: 'rzp_live_FoTbWwcCT8hpO6',
  razorpay_name: 'VOICESNAP-DIALOUTS',
  razorpay_description: 'Thank you for Voicesnap',
  razorpay_image: 'assets/imgs/logo1.png',


};


import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Subscription } from 'rxjs';
import { UsersSandbox } from 'src/app/pages/users/users.sandbox';
import * as $ from 'jquery';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.scss']
})
export class SettingsComponent implements OnInit {
  form: FormGroup
  availability = []
  public subscriptions: Array<Subscription> = [];
  data = 1;
  currentAvailabity = 'Available';
  dataArr = [
    { id: 1, name: 'Availability Status', isActive: true },
    { id: 2, name: 'Change Password', isActive: false },
    // { id: 3, name: 'Forget Password' },
  ]
  remarks = '';

  constructor(
    public activeModal: NgbActiveModal,
    private fb: FormBuilder,
    public usersSandbox: UsersSandbox,
  ) { }

  ngOnInit() {
    this.initForm();
    this.form.controls['membername'].setValue(JSON.parse(localStorage.getItem('userDetails')).member_name);
    this.usersSandbox.callAvailability({})
    this.subscriptions.push(this.usersSandbox.callAvailability$.subscribe((res) => {
      if (res && res.length > 0) {
        this.availability = res
      }
    }))
  }
  initForm() {
    this.form = this.fb.group({
      membername: [null],
      password: [null, Validators.required],
      newPassword: [null, Validators.required],
    })
  }
  onSelect(item) {
    this.data = item.id
    this.dataArr.forEach((data) => data.isActive = false)
    item.isActive = true;
  }

  OnChangeAvailability(state) {
    this.availability.forEach((data) => data.active = false);
    state.active = true
    // this.form.controls['call_availability'].setValue(state.unnest);
    this.currentAvailabity = state.unnest;

  }

  onConfirm() {
    if (this.dataArr[this.data - 1].id == 1) {
      let params: any = {};
      params.member_id = JSON.parse(localStorage.getItem('userDetails')).member_id;
      params.call_availability = this.currentAvailabity;
      params.remarks = this.remarks;
      this.usersSandbox.updateAvailability(params);
      this.subscriptions.push(this.usersSandbox.updateAvailabilityLoaded$.subscribe((res) => {
        if (res) {
          this.close();
        }
      }))

    }
    if (this.dataArr[this.data - 1].id == 2) {
      if (!this.form.valid) {
        this.validateAllFormFields(this.form);
        let target;
        target = $('input.ng-invalid' || 'select.ng-invalid').first();
        if (target) {
          $('html,body').animate(
            { scrollTop: $(target).offset().top - 310 },
            'slow',
            () => {
              target.focus();
            }
          );
        }
        return;
      }
      if (this.form.valid) {
        let params: any = {};
        params.old_password = this.form.value.password;
        params.new_password = this.form.value.newPassword;
        this.usersSandbox.changePassword(params);
        this.subscriptions.push(this.usersSandbox.changePasswordLoaded$.subscribe((res) => {
          if (res) {
            this.close();
          }
        }))

      }
    }


  }
  validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({ onlySelf: true });
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
  close() {
    this.activeModal.close();
  }
}

import { Action } from '@ngrx/store';
import { ResponseInterface } from 'src/app/shared/interfaces/interface';
import { type } from 'src/app/shared/utility';

export const ActionTypes = {


    CAMPAIGN_LIST: type('[campaign] campaign list '),
    CAMPAIGN_LIST_SUCCESS: type('[campaign] campaign list Success'),
    CAMPAIGN_LIST_FAIL: type('[campaign] campaign list Fail'),

    CAMPAIGN_LIST_COUNT: type('[campaign] campaign list count '),
    CAMPAIGN_LIST_COUNT_SUCCESS: type('[campaign] campaign list count Success'),
    CAMPAIGN_LIST_COUNT_FAIL: type('[campaign] campaign list count Fail'),

    CREATE_CAMPAIGN: type('[campaign] Create Campaign '),
    CREATE_CAMPAIGN_SUCCESS: type('[campaign] Create Campaign Success'),
    CREATE_CAMPAIGN_FAIL: type('[campaign] Create Campaign Fail'),

    DOWNLOAD_FILE: type('[campaign] DOWNLOAD_FILE'),
    DOWNLOAD_FILE_SUCCESS: type('[campaign] DOWNLOAD_FILE_SUCCESS'),
    DOWNLOAD_FILE_FAIL: type('[campaign] DOWNLOAD_FILE_FAIL'),

    GET_HOURS_TO_DIAL: type('[campaign] GET_HOURS_TO_DIAL'),
    GET_HOURS_TO_DIAL_SUCCESS: type('[campaign] GET_HOURS_TO_DIAL_SUCCESS'),
    GET_HOURS_TO_DIAL_FAIL: type('[campaign] GET_HOURS_TO_DIAL_FAIL'),


    GET_MINUTES_TO_DIAL: type('[campaign] GET_MINUTES_TO_DIAL'),
    GET_MINUTES_TO_DIAL_SUCCESS: type('[campaign] GET_MINUTES_TO_DIAL_SUCCESS'),
    GET_MINUTES_TO_DIAL_FAIL: type('[campaign] GET_MINUTES_TO_DIAL_FAIL'),

    STOP_CAMP: type('[campaign] STOP_CAMP '),
    STOP_CAMP_SUCCESS: type('[campaign] STOP_CAMP_SUCCESS'),
    STOP_CAMP_FAIL: type('[campaign] STOP_CAMP_FAIL'),

    GENERATE_CASH: type('[campaign] GENERATE_CASH'),
    GENERATE_CASH_SUCCESS: type('[campaign] GENERATE_CASH_SUCCESS'),
    GENERATE_CASH_FAIL: type('[campaign] GENERATE_CASH_FAIL'),

    UPDATE_PAYMENT_STATUS: type('[campaign] UPDATE_PAYMENT_STATUS'),
    UPDATE_PAYMENT_STATUS_SUCCESS: type('[campaign] UPDATE_PAYMENT_STATUS success'),
    UPDATE_PAYMENT_STATUS_FAIL: type('[campaign] UPDATE_PAYMENT_STATUS fail'),

    ORGANISATION_CREDITS: type('[campaign] ORGANISATION_CREDITS'),
    ORGANISATION_CREDITS_SUCCESS: type('[campaign] ORGANISATION_CREDITS_SUCCESS'),
    ORGANISATION_CREDITS_FAIL: type('[campaign] ORGANISATION_CREDITS_FAIL'),

    CREDIT_TRANSACTION_LIST: type('[campaign] CREDIT_TRANSACTION_LIST'),
    CREDIT_TRANSACTION_LIST_SUCCESS: type('[campaign] CREDIT_TRANSACTION_LIST_SUCCESS'),
    CREDIT_TRANSACTION_LIST_FAIL: type('[campaign] CREDIT_TRANSACTION_LIST_FAIL'),
    
    CREDIT_TRANSACTION_LIST_COUNT: type('[campaign] CREDIT_TRANSACTION_LIST_COUNT'),
    CREDIT_TRANSACTION_LIST_COUNT_SUCCESS: type('[campaign] CREDIT_TRANSACTION_LIST_COUNT_SUCCESS'),
    CREDIT_TRANSACTION_LIST_COUNT_FAIL: type('[campaign] CREDIT_TRANSACTION_LIST_COUNT_FAIL'),

};

// CAMPAIGN LIST

export class CampaignList implements Action {
    type = ActionTypes.CAMPAIGN_LIST;

    constructor(public payload: any) {
    }
}

export class CampaignListSuccess implements Action {
    type = ActionTypes.CAMPAIGN_LIST_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class CampaignListFail implements Action {
    type = ActionTypes.CAMPAIGN_LIST_FAIL;

    constructor(public payload: any) {
    }
}

// CAMPAIGN LIST COUNT
export class CampaignListCount implements Action {
    type = ActionTypes.CAMPAIGN_LIST_COUNT;

    constructor(public payload: any) {
    }
}

export class CampaignListCountSuccess implements Action {
    type = ActionTypes.CAMPAIGN_LIST_COUNT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class CampaignListCountFail implements Action {
    type = ActionTypes.CAMPAIGN_LIST_COUNT_FAIL;

    constructor(public payload: any) {
    }
}

// CREATE CAMPAIGN
export class CreateCampaign implements Action {
    type = ActionTypes.CREATE_CAMPAIGN;

    constructor(public payload: any) {
    }
}

export class CreateCampaignSuccess implements Action {
    type = ActionTypes.CREATE_CAMPAIGN_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class CreateCampaignFail implements Action {
    type = ActionTypes.CREATE_CAMPAIGN_FAIL;

    constructor(public payload: any) {
    }
}

// DownloadFile
export class DownloadFile implements Action {
    type = ActionTypes.DOWNLOAD_FILE;
    constructor(public payload: ResponseInterface) {
    }
}
export class DownloadFileSuccess implements Action {
    type = ActionTypes.DOWNLOAD_FILE_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}

export class DownloadFileFail implements Action {
    type = ActionTypes.DOWNLOAD_FILE_FAIL;
    constructor(public payload: any) {
    }
}

export class get_hours_to_dial implements Action {
    type = ActionTypes.GET_HOURS_TO_DIAL;
    constructor() {
    }
}
export class get_hours_to_dialSuccess implements Action {
    type = ActionTypes.GET_HOURS_TO_DIAL_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}

export class get_hours_to_dialFail implements Action {
    type = ActionTypes.GET_HOURS_TO_DIAL_FAIL;
    constructor(public payload: any) {
    }
}

export class get_minutes_to_dial implements Action {
    type = ActionTypes.GET_MINUTES_TO_DIAL;
    constructor() {
    }
}
export class get_minutes_to_dialSuccess implements Action {
    type = ActionTypes.GET_MINUTES_TO_DIAL_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}

export class get_minutes_to_dialFail implements Action {
    type = ActionTypes.GET_MINUTES_TO_DIAL_FAIL;
    constructor(public payload: any) {
    }
}

// stopCamp

export class stopCamp implements Action {
    type = ActionTypes.STOP_CAMP;
    constructor(public payload: any) {
    }
}

export class stopCampSuccess implements Action {
    type = ActionTypes.STOP_CAMP_SUCCESS;
    constructor(public payload: ResponseInterface) {
    }
}

export class stopCampFail implements Action {
    type = ActionTypes.STOP_CAMP_FAIL;

    constructor(public payload: any) {
    }
}

export class generateCashAction implements Action {
    type = ActionTypes.GENERATE_CASH;
    
    constructor(public payload: any) {
    }
}
export class generateCashSuccess implements Action {
    type = ActionTypes.GENERATE_CASH_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}
export class generateCashFail implements Action {
    type = ActionTypes.GENERATE_CASH_FAIL;
    constructor(public payload: any) {
    }
}

export class UpdatePaymentStatus implements Action {
    type = ActionTypes.UPDATE_PAYMENT_STATUS;

    constructor(public payload: any) { }
}

export class UpdatePaymentStatusSuccess implements Action {
    type = ActionTypes.UPDATE_PAYMENT_STATUS_SUCCESS;

    constructor(public payload: ResponseInterface) { }
}

export class UpdatePaymentStatusFail implements Action {
    type = ActionTypes.UPDATE_PAYMENT_STATUS_FAIL;

    constructor(public payload: ResponseInterface) { }
}
//CHECK_CREDITS
export class organisationCredits implements Action {
    type = ActionTypes.ORGANISATION_CREDITS;
}

export class organisationCreditsSuccess implements Action {
    type = ActionTypes.ORGANISATION_CREDITS_SUCCESS;
    constructor(public payload: ResponseInterface) { }
}

export class organisationCreditsFail implements Action {
    type = ActionTypes.ORGANISATION_CREDITS_FAIL;
    constructor(public payload: any) { }
}

export class CreditTransactionList implements Action {
    type = ActionTypes.CREDIT_TRANSACTION_LIST;

    constructor(public payload: any) {
    }
}

export class CreditTransactionListSuccess implements Action {
    type = ActionTypes.CREDIT_TRANSACTION_LIST_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class CreditTransactionListFail implements Action {
    type = ActionTypes.CREDIT_TRANSACTION_LIST_FAIL;

    constructor(public payload: any) {
    }
}

// CAMPAIGN LIST COUNT
export class CreditTransactionListCount implements Action {
    type = ActionTypes.CREDIT_TRANSACTION_LIST_COUNT;

    constructor(public payload: any) {
    }
}

export class CreditTransactionListCountSuccess implements Action {
    type = ActionTypes.CREDIT_TRANSACTION_LIST_COUNT_SUCCESS;

    constructor(public payload: ResponseInterface) {
    }
}

export class CreditTransactionListCountFail implements Action {
    type = ActionTypes.CREDIT_TRANSACTION_LIST_COUNT_FAIL;

    constructor(public payload: any) {
    }
}


export type Actions =
    | CampaignList
    | CampaignListSuccess
    | CampaignListFail

    | CampaignListCount
    | CampaignListCountSuccess
    | CampaignListCountFail

    | CreateCampaign
    | CreateCampaignSuccess
    | CreateCampaignFail

    | CreditTransactionList
    | CreditTransactionListSuccess
    | CreditTransactionListFail

    | CreditTransactionListCount
    | CreditTransactionListCountSuccess
    | CreditTransactionListCountFail




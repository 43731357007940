import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ProjectsSandbox } from 'src/app/pages/projects/projects.sandbox';
import { CustomersSandbox } from 'src/app/pages/customers/customers.sandbox';
import * as fs from 'file-saver';


@Component({
  selector: 'app-bulk-upload-file',
  templateUrl: './bulk-upload-file.component.html',
  styleUrls: ['./bulk-upload-file.component.scss']
})
export class BulkUploadFileComponent implements OnInit, OnDestroy {
  @ViewChild('file') file: ElementRef;
  type = '';
  fileName: string = ''
  fileDate: any = ''
  public subscriptions: Array<Subscription> = [];
  constructor(
    public activeModal: NgbActiveModal,
    public toster: ToastrService,
    public projectsSandbox: ProjectsSandbox,
    public customersSandbox: CustomersSandbox
  ) { }

  ngOnInit() {
  }

  chooseFile() {
    this.file.nativeElement.click()
  }
  selectFile(event) {
    const file = event.target.files[0]
    this.fileName = file.name
    this.fileDate = file
  }
  onUpload() {
    const obj = {
      ['File Name']: this.fileName,
      ['File']: this.fileDate
    }
    for (const param in obj) {
      if (obj[param] === '') {
        return this.toster.error('Please Choose File');
      }
    }
    const params = {
      type: this.type,
      file: this.fileDate
    }
    
    this.customersSandbox.bulkUploadFile(params)
    this.subscriptions.push(this.customersSandbox.bulkUploadData$.subscribe((res) => {
      if (res) {
        
        const Excel = require('exceljs');
        // Create Excel workbook and worksheet
        const workbook = new Excel.Workbook();
        const worksheet = workbook.addWorksheet('Customers');
        const resultData: any =  res.data //request.body.invalidExamdata;
      
        let col = []
        //  let row : any = [];
        for (const data of Object.keys(resultData[0])) {
          var header = { header: data, key: data, width: 20 };
          col.push(header);
        }
        worksheet.columns = col;
        resultData.map((data) => {
          let R = {};
          col.map((columval) => {
      
            const output1 = data.hasOwnProperty(columval.header);
      
      
            if (output1) {
              R[columval.header] = data[columval.header];
            }
          });
          var row = worksheet.addRow(R);
          if (data.hasOwnProperty('Reason') && data['Reason'] != '') {
            row.fill = {
              type: 'gradient',
              gradient: 'angle',
              degree: 0,
      
              stops: [
                { position: 0, color: { argb: 'ffffff' } },
                { position: 0.5, color: { argb: 'cc8188' } },
                { position: 1, color: { argb: 'fa071e' } }
              ]
            };
          }
      
        });
        
         workbook.xlsx.writeBuffer().then((data) => {
          let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
             fs.saveAs(blob, 'InvalidUploadedMarks.xlsx');
      });
        this.activeModal.close('success')
      }
    }))
  }

  close() {
    this.activeModal.close();
  }
  downloadTemplate(){
    this.customersSandbox.downloadFile({});
  }
  ngOnDestroy() {
    this.subscriptions.forEach(each => each.unsubscribe());
  }
}

import { CampaignState, campaignStateRecord } from './campaign.state';
import * as actions from '../action/campaign.action';

export const initialState: CampaignState = new campaignStateRecord() as unknown as CampaignState;

export function reducer(state = initialState, { type, payload }: any): CampaignState {
    if (!type) {
        return state;
    }

    switch (type) {

        //  CAMPAIGN LIST
        case actions.ActionTypes.CAMPAIGN_LIST:

            return Object.assign({}, state, {
                campaignListLoading: true,
                campaignListLoaded: false,
                campaignListFailed: false,
            });

        case actions.ActionTypes.CAMPAIGN_LIST_SUCCESS:
            console.log('campaignList', payload.data)
            return Object.assign({}, state, {
                campaignList: payload.data,
                campaignListLoading: false,
                campaignListLoaded: true,
                campaignListFailed: false,
            });

        case actions.ActionTypes.CAMPAIGN_LIST_FAIL:

            return Object.assign({}, state, {
                campaignListLoading: false,
                campaignListLoaded: true,
                campaignListFailed: true,

            });

        //  CAMPAIGN LIST COUNT
        case actions.ActionTypes.CAMPAIGN_LIST_COUNT:

            return Object.assign({}, state, {
                campaignListCountLoading: true,
                campaignListCountLoaded: false,
                campaignListCountFailed: false,
            });

        case actions.ActionTypes.CAMPAIGN_LIST_COUNT_SUCCESS:

            return Object.assign({}, state, {
                campaignListCount: payload.data,
                campaignListCountLoading: false,
                campaignListCountLoaded: true,
                campaignListCountFailed: false,
            });

        case actions.ActionTypes.CAMPAIGN_LIST_COUNT_FAIL:

            return Object.assign({}, state, {
                campaignListCountLoading: false,
                campaignListCountLoaded: true,
                campaignListCountFailed: true,

            });

        // CREATE CAMPAIGN
        case actions.ActionTypes.CREATE_CAMPAIGN:

            return Object.assign({}, state, {
                createCampaignLoading: true,
                createCampaignLoaded: false,
                createCampaignFailed: false,
            });

        case actions.ActionTypes.CREATE_CAMPAIGN_SUCCESS:

            return Object.assign({}, state, {
                createCampaign: payload,
                createCampaignLoading: false,
                createCampaignLoaded: true,
                createCampaignFailed: false,
            });

        case actions.ActionTypes.CREATE_CAMPAIGN_FAIL:

            return Object.assign({}, state, {
                createCampaignLoading: false,
                createCampaignLoaded: true,
                createCampaignFailed: true,

            });


        //  get_hours_to_dial
        case actions.ActionTypes.GET_HOURS_TO_DIAL:

            return Object.assign({}, state, {
                get_hours_to_dialLoading: true,

            });

        case actions.ActionTypes.GET_HOURS_TO_DIAL_SUCCESS:
            console.log('get_hours_to_dial', payload.data)
            return Object.assign({}, state, {
                get_hours_to_dial: payload.data,
                get_hours_to_dialLoading: false,
            });

        case actions.ActionTypes.GET_HOURS_TO_DIAL_FAIL:

            return Object.assign({}, state, {
                get_hours_to_dialLoading: false,

            });

        // get_minutes_to_dial
        case actions.ActionTypes.GET_MINUTES_TO_DIAL:

            return Object.assign({}, state, {
                get_minutes_to_dialLoading: true,

            });

        case actions.ActionTypes.GET_MINUTES_TO_DIAL_SUCCESS:
            console.log('get_minutes_to_dial', payload.data)
            return Object.assign({}, state, {
                get_minutes_to_dial: payload.data,
                get_minutes_to_dialLoading: false,
            });

        case actions.ActionTypes.GET_MINUTES_TO_DIAL_FAIL:

            return Object.assign({}, state, {
                get_minutes_to_dialLoading: false,

            });

        // stopCamp
        case actions.ActionTypes.STOP_CAMP:

            return Object.assign({}, state, {
                stopCampLoading: true,

            });

        case actions.ActionTypes.STOP_CAMP_SUCCESS:
            console.log('STOP_CAMP_SUCCESS', payload)
            return Object.assign({}, state, {
                stopCamp: payload.data,
                stopCampLoading: false,
            });

        case actions.ActionTypes.STOP_CAMP_FAIL:

            return Object.assign({}, state, {
                stopCampLoading: false,

            });

        case actions.ActionTypes.GENERATE_CASH:
            return Object.assign({}, state, {
                generateCash: [],
                generateCashLoading: true,
            });

        case actions.ActionTypes.GENERATE_CASH_SUCCESS:
            return Object.assign({}, state, {
                generateCash: payload,
                generateCashLoading: false,
            });

        case actions.ActionTypes.GENERATE_CASH_FAIL:
            return Object.assign({}, state, {
                generateCashLoading: false,
            });

        case actions.ActionTypes.UPDATE_PAYMENT_STATUS:
            return Object.assign({}, state, {
                updateApplicationPaymentStatus: [],
                updateApplicationPaymentStatusLoading: true,
            });

        case actions.ActionTypes.UPDATE_PAYMENT_STATUS_SUCCESS:
            return Object.assign({}, state, {
                updateApplicationPaymentStatus: payload,
                updateApplicationPaymentStatusLoading: false,
            });

        case actions.ActionTypes.UPDATE_PAYMENT_STATUS_FAIL:
            return Object.assign({}, state, {
                updateApplicationPaymentStatus: [],
                updateApplicationPaymentStatusLoading: false,
            });

        case actions.ActionTypes.ORGANISATION_CREDITS:
            return Object.assign({}, state, {
                organisationCredits: [],
                organisationCreditsLoading: true,
            });

        case actions.ActionTypes.ORGANISATION_CREDITS_SUCCESS:
            console.log("logggg");
            return Object.assign({}, state, {
                organisationCredits: payload,
                organisationCreditsLoading: false,
            });

        case actions.ActionTypes.ORGANISATION_CREDITS_FAIL:
            return Object.assign({}, state, {
                organisationCredits: [],
                organisationCreditsLoading: false,
            });

        //  CAMPAIGN LIST
        case actions.ActionTypes.CREDIT_TRANSACTION_LIST:

            return Object.assign({}, state, {
                CreditTransactionListLoading: true,
                CreditTransactionListLoaded: false,
                CreditTransactionListFailed: false,
            });

        case actions.ActionTypes.CREDIT_TRANSACTION_LIST_SUCCESS:
            console.log('hellooooo', payload.data)
            return Object.assign({}, state, {
                CreditTransactionList: payload.data,
                CreditTransactionListLoading: false,
                CreditTransactionListLoaded: true,
                CreditTransactionListFailed: false,
            });

        case actions.ActionTypes.CREDIT_TRANSACTION_LIST_FAIL:

            return Object.assign({}, state, {
                CreditTransactionListLoading: false,
                CreditTransactionListLoaded: true,
                CreditTransactionListFailed: true,

            });

        //  CAMPAIGN LIST COUNT
        case actions.ActionTypes.CREDIT_TRANSACTION_LIST_COUNT:

            return Object.assign({}, state, {
                CreditTransactionListCountLoading: true,
                CreditTransactionListCountLoaded: false,
                CreditTransactionListCountFailed: false,
            });

        case actions.ActionTypes.CREDIT_TRANSACTION_LIST_COUNT_SUCCESS:
            console.log('CREDIT_TRANSACTION_LIST_COUNT_SUCCESS', payload.data)
            return Object.assign({}, state, {
                CreditTransactionListCount: payload.data,
                CreditTransactionListCountLoading: false,
                CreditTransactionListCountLoaded: true,
                CreditTransactionListCountFailed: false,
            });

        case actions.ActionTypes.CREDIT_TRANSACTION_LIST_COUNT_FAIL:

            return Object.assign({}, state, {
                CreditTransactionListCountLoading: false,
                CreditTransactionListCountLoaded: true,
                CreditTransactionListCountFailed: true,

            });

        default: {
            return state;
        }
    }
}

/**
 * export values
 */


export const campaignList = (state: CampaignState) => state.campaignList;
export const campaignListLoading = (state: CampaignState) => state.campaignListLoading;
export const campaignListLoaded = (state: CampaignState) => state.campaignListLoaded;
export const campaignListFailed = (state: CampaignState) => state.campaignListFailed;

export const campaignListCount = (state: CampaignState) => state.campaignListCount;
export const campaignListCountLoading = (state: CampaignState) => state.campaignListCountLoading;
export const campaignListCountLoaded = (state: CampaignState) => state.campaignListCountLoaded;
export const campaignListCountFailed = (state: CampaignState) => state.campaignListCountFailed;

export const createCampaign = (state: CampaignState) => state.createCampaign;
export const createCampaignLoading = (state: CampaignState) => state.createCampaignLoading;
export const createCampaignLoaded = (state: CampaignState) => state.createCampaignLoaded;
export const createCampaignFailed = (state: CampaignState) => state.createCampaignFailed;

export const get_hours_to_dial = (state: CampaignState) => state.get_hours_to_dial;
export const get_hours_to_dialLoading = (state: CampaignState) => state.get_hours_to_dialLoading;

export const get_minutes_to_dial = (state: CampaignState) => state.get_minutes_to_dial;
export const get_minutes_to_dialLoading = (state: CampaignState) => state.get_minutes_to_dialLoading;

export const stopCamp = (state: CampaignState) => state.stopCamp;
export const stopCampLoading = (state: CampaignState) => state.stopCampLoading;

export const generateCash = (state: CampaignState) => state.generateCash;
export const generateCashLoading = (state: CampaignState) => state.generateCashLoading;

export const updateApplicationPaymentStatus = (state: CampaignState) =>
    state.updateApplicationPaymentStatus;

export const updateApplicationPaymentStatusLoading = (state: CampaignState) =>
    state.updateApplicationPaymentStatusLoading;
//checkCredits
export const organisationCredits = (state: CampaignState) => state.organisationCredits;
export const organisationCreditsLoading = (state: CampaignState) => state.organisationCreditsLoading;

export const CreditTransactionList = (state: CampaignState) => state.CreditTransactionList;
export const CreditTransactionListLoading = (state: CampaignState) => state.CreditTransactionListLoading;
export const CreditTransactionListLoaded = (state: CampaignState) => state.CreditTransactionListLoaded;
export const CreditTransactionListFailed = (state: CampaignState) => state.CreditTransactionListFailed;

export const CreditTransactionListCount = (state: CampaignState) => state.CreditTransactionListCount;
export const CreditTransactionListCountLoading = (state: CampaignState) => state.CreditTransactionListCountLoading;
export const CreditTransactionListCountLoaded = (state: CampaignState) => state.CreditTransactionListCountLoaded;
export const CreditTransactionListCountFailed = (state: CampaignState) => state.CreditTransactionListCountFailed;
import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ProjectsSandbox } from 'src/app/pages/projects/projects.sandbox';
import { CustomersSandbox } from 'src/app/pages/customers/customers.sandbox';

@Component({
  selector: 'app-move-to-crm',
  templateUrl: './move-to-crm.component.html',
  styleUrls: ['./move-to-crm.component.scss']
})
export class MoveToCRMComponent implements OnInit, OnDestroy {
  public subscriptions: Array<Subscription> = [];
  agent = null;
  did = null;
  details: any;
  constructor(
    public activeModal: NgbActiveModal,
    public toster: ToastrService,
    public customerSandbox: CustomersSandbox,
  ) { }

  ngOnInit() {
    this.agentAndDIDList();
  }
  agentAndDIDList() {
    this.customerSandbox.crmAgentList();
    this.customerSandbox.crmDidList();
  }
  onSubmit() {
    if (this.agent == null || this.did == null) {
      this.toster.error('Please Choose Agent and DID');
      return false
    } else {
      let params: any = {}
      params.customer_id = this.details.customer_id;
      params.crm_did_id = this.did;
      params.crm_project_id = this.details.project_id;
      params.crm_agent_id = this.agent;
      this.customerSandbox.moveToCrm(params);
      this.subscriptions.push(this.customerSandbox.movetocrmLoader$.subscribe((res) => {
        if (res) {
          this.activeModal.close('success')
        }
      }))


    }
  }
  close() {
    this.activeModal.close();
  }
  ngOnDestroy() {
    this.subscriptions.forEach(each => each.unsubscribe());
  }
}

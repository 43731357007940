import { Component, OnDestroy, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { LiveMoniterSandbox } from 'src/app/pages/live-moniter/liveMoniter.sandbox';
import { ProjectsSandbox } from 'src/app/pages/projects/projects.sandbox';


@Component({
  selector: 'app-whatsapp-template',
  templateUrl: './whatsapp-template.component.html',
  styleUrls: ['./whatsapp-template.component.scss']
})
export class WhatsappTemplateComponent implements OnInit, OnDestroy {
  public subscriptions: Array<Subscription> = [];
  details: any;
  project_id: any;
  smsContentNew: any;
  smsContentValue: any;
  template_table_id: any;
  customer_id: any;
  template_type: any;
  test_value: any = [];
  document_id: any = null;
  template_id = null;

  constructor(
    public activeModal: NgbActiveModal,
    public liveMoniterSandbox: LiveMoniterSandbox,
    public projectsSandbox: ProjectsSandbox,
    public toster: ToastrService,
  ) { }

  ngOnInit() {
    this.getTemplate();
    this.fetchFileList();
  }
  getTemplate() {
    this.project_id = this.details.project_id;
    this.customer_id = this.details.customer_id;
    const params = {
      project_id: this.details.project_id
    }
    this.liveMoniterSandbox.getTemplate(params);
  }

  fetchFileList() {
    const params = {
      project_id: this.details.project_id
    }
    this.projectsSandbox.fileList(params)
  }

  changeProject(event) {
    this.document_id = event.document_id;
  }



  changeTemplate(event) {
    this.template_id = event.template_id;
    this.document_id = '';
    this.smsContentNew = event.template_text;
    const val = event.template_text;
    const val2 = val.replaceAll('#var#', // val.replace(/\{#.*?\#}/g,
      `<input type = "text" class = "wtn" maxlength="30" 
    style = "text-align:left;margin:1%;padding:1%;font-size: 15px;
    font-weight: normal;border: 1px solid black;border-radius: 5px;
    color: #4f5050;
    
    font-family: 'Cabin', sans-serif;" #aaaaaaa >
    `);
    const val3 = val2;
    const val4 = val3.trim();
    let val5;
    val5 = '<!doctype html>' + '<html>'
    for (let i = 0; i < val4.length; i++) {
      val5 += val4[i];
    }
    val5 += '</html>'
    const element: HTMLElement = document.getElementById('smsC') as HTMLElement;
    element.innerHTML = val5;
    this.template_id = event.template_id;
    this.template_table_id = event.id;
    this.template_type = event.template_type;
  }

  onSubmit() {
    let val2;
    let val;
    val2 = this.smsContentNew ? this.smsContentNew.split('#var#') : '';
    val = document.getElementById("smsC").getElementsByTagName('input');
    let s = 0;
    for (let i = 0; i < val2.length; i++) {
      if (val.length > s) {
        if (val[s].type == "text") {
          var textValue = val[s].value;
          this.test_value.push(textValue);
          s++;
        }
      }
    }
    if (this.template_id == null || this.template_id == '') {
      this.toster.error('Please Choose Template');
      return false;
    }
    if (this.template_type == 'Media') {
      if (this.document_id == null || this.document_id == '') {
        this.toster.error('Please Choose Document');
        return false;
      }
    }
    const params = {
      template_id: this.template_id,
      template_table_id: this.template_table_id,
      project_id: this.project_id,
      template_text_array: this.test_value,
      template_type: this.template_type,
      customer_id: this.customer_id,
      document_id: this.document_id ? this.document_id : ''
    }
    this.liveMoniterSandbox.sendWhatsappMsg(params);
    this.subscriptions.push(this.liveMoniterSandbox.sendWhatsappMsg$.subscribe((res) => {
      if (res) {
        this.activeModal.close('success')
      }
    }));
  }


  close() {
    this.activeModal.close();
  }
  ngOnDestroy() {
    this.subscriptions.forEach(each => each.unsubscribe());
  }
}
